<template>
  <div>
    <span class="mr-3 text-indigo-900">{{ label }}</span>
    <div class="w-44 lg:w-72" :class="widthClass">
      <multiselect
        placeholder=""
        select-label=""
        deselect-label=""
        selected-label=""
        group-values="items"
        group-label="label"
        track-by="value"
        label="label"
        :options="options"
        :value="value"
        :multiple="multiple"
        @input="handleInput"
      />
    </div>
  </div>
</template>

<script>
import AgenceFilter from "@/model/AgenceFilter";
import { mapState } from "vuex";

export default {
  name: "AgenceFilter",
  props: {
    value: {
      type: [Array, Object],
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    widthClass: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    label() {
      return "Agence" + (this.multiple ? "s" : "");
    },

    options() {
      return this.agences.reduce((acc, current) => {
        let marqueIndex = acc.findIndex((m) => m.label === current.marque.nom);
        if (marqueIndex === -1) {
          acc.push({
            label: current.marque.nom,
            items: [],
          });
          marqueIndex = acc.length - 1;
        }
        acc[marqueIndex].items.push({
          label: current.nom,
          value: current.uuid,
        });
        return acc;
      }, []);
    },

    ...mapState({
      agences: (state) => state.agences.agences,
    }),
  },
  created() {
    this.$store
      .dispatch("agences/getAgences", { agenceFilter: new AgenceFilter() })
      .then(() => {
        if (this.multiple || this.value.value) {
          return;
        }
        const firstValue = {
          label: this.agences[0].nom,
          value: this.agences[0].uuid,
        };
        this.$emit("input", { field: this.fields[0], value: firstValue });
      });
  },
  methods: {
    handleInput(value) {
      this.$emit("input", { field: this.fields[0], value });
    },
  },
};
</script>
